import React from "react";
import { PageLayout } from "~components/templates/page-layout";
import * as styles from "./index.module.scss";

const Component: React.FC = () => {
  return (
    <>
      <PageLayout
        paddingPattern="larger-than-header"
        meta={{
          title: "セキュリティ診断プラン 無料シミュレーション",
          description:
            "このシミュレーターでは、5つの質問に答えるだけであなたに最適なセキュリティ診断のプランをお勧めします。シミュレーション結果はメールでお知らせしますので、メールアドレスを間違えないようご注意ください。また、本シミュレーションはWebアプリケーションとスマートフォンアプリケーションのみを対象としていますが、IoTなどそれ以外のプロダクトもセキュリティ診断の実施は可能ですので、お気軽にお問い合わせください。",
        }}
      >
        <div className={styles.AssessmentPlanSimulation_Wrapper}>
          <iframe
            className={styles.AssessmentPlanSimulation_Container}
            src="https://docs.google.com/forms/d/e/1FAIpQLScu8naI4HF4-kSnq8Ycmo4mOy8FDVCv4Xka3YuyJP01mv7jwQ/viewform?embedded=true"
            width="640"
            height="2156"
            frameBorder="0"
          >
            読み込んでいます…
          </iframe>
          <p className={styles.AssessmentPlanSimulation_Note}>
            ※フォーム送信後のメッセージは画面最上部に表示されるので、
            <br />
            お手数ですが画面最上部までスクロールをお願いいたします。
          </p>
        </div>
      </PageLayout>
    </>
  );
};

export default Component;
